import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone, faHome } from "@fortawesome/free-solid-svg-icons";

class Contact extends Component {
    render() {
        return (
            <div id="contact" className="contact">
                <div className="container">
                    <h1 className="section-title yellow">
                        Contact
                    </h1>
                    <div className="contact-wrapper">
                        <div className="contact-content left">
                            <p>Moet ik voor meer dan zes personen rollen, bakken en grillen? Neem dan contact met mij op.</p>
                            <div className="contact-content__address">
                                <a href="https://goo.gl/maps/vYmjfsMpfAfNDsNs9" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faHome} />Notenlaantje 8, Lent</a>
                                <a href="tel:0623999074"><FontAwesomeIcon icon={faPhone} />06 2399 9074</a>
                                <a href="mailto:hoi@sushivankai.nl"><FontAwesomeIcon icon={faEnvelope} />hoi@sushivankai.nl</a>
                            </div>
                            <div className="contact-content__socials">
                                <a href="https://wa.me/+31623999074" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                                <a href="https://www.instagram.com/sushivankai/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                                <a href="https://www.facebook.com/Sushi-van-Kai-105188018578987" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                                <a href="mailto:hoi@sushivankai.nl" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faEnvelope} /></a>
                            </div>
                        </div>

                        <div className="contact-content right">
                            <div id="map" className="contact-content__maps">
                                <iframe title="GoogleMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2463.505207891556!2d5.86336331567128!3d51.8699899796964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c70956dbbb5dad%3A0xf497c014dd3f6d4f!2sSushi%20van%20Kai!5e0!3m2!1snl!2snl!4v1623700019101!5m2!1snl!2snl" style={{border:0}} loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;