import React from "react";
import Banner from "./main/Banner";
import Bestellen from "./main/Bestellen";
import Contact from "./main/Contact";
import Header from "./main/Header";
// import Instagram from "./main/Instagram";
import Visie from "./main/Visie";
import OverKai from "./main/OverKai";
import Trainingen from "./main/Trainingen";
import PopupBox from "./PopupBox";

function Main({ callPopupVisible, openOrderForm, openPopup, toggleCallPopup }) {
  return (
    <React.StrictMode>
      {callPopupVisible && <PopupBox toggleCallPopup={toggleCallPopup} />}
      <Header />
      <OverKai />
      <Banner />
      <Visie />
      <Bestellen openOrderForm={openOrderForm} />
      <Trainingen openPopup={openPopup} />
      <Contact />
    </React.StrictMode>
  );
}

export default Main;
