import React from "react";

function Visie() {
  return (
    <div id="visie" className="visie">
      <h1 className="section-title yellow">Visie en Kookwijze</h1>
      <div className="container visie-wrapper">
        <div className="visie-left">
          <h2 className="subtitle yellow">Visie</h2>
          <p>
            Mijn visie op lekker eten is vrij simpel. Biologisch, vers, lokaal
            en kwaliteit!
          </p>
          <br />
          <p>
            In mijn eigen (koel)kast is alles biologisch. Waarom? Ik denk dat
            biologisch eten de toekomst is. Onbespoten groenten levert misschien
            wat minder hoeveelheid op, maar het zit nog vol leven, mineralen en
            vitaminen. Daarom heb ik er ook minder van nodig. En ja de prijs is
            hoger, maar uiteindelijk levert het mij, mijn kinderen en de wereld
            meer op. Insecten kunnen ook mee eten en dat vind ik een fijn idee.
            Een stap verder dan bio is het "Demeter" keurmerk. Deze visie is
            niet alleen bio maar ook holistisch. Een wijze van land verbouwen
            met respect voor plant, dier en mens. Goed eten voor lichaam én
            geest. Alles staat met elkaar in verbinding. Fijn om te eten en te
            verbouwen.
          </p>
          <br />
          <p>
            Sommige ingrediënten kan ik niet biologisch verkrijgen. Zoals bijvoorbeeld 
            zeewier. Die kunnen niet biologisch gecertificeerd worden omdat het in de 
            natuur groeit. Hiervoor kies ik dan altijd het meest verse of de beste kwaliteit.
          </p>
          <br />
          <p>
            Kimchi is een goed voorbeeld van lokaal. Do van <a href="https://www.basictheoryferments.nl/" target="_blank" rel="noreferrer noopener">Basic Theory
            Ferments</a> maakt fantastisch gefermenteerde producten. Haar
            kimchi is geweldig. Nóg niet bio gecertificeerd maar dat komt snel.
            Maar ik haal mijn kimchi liever hier uit Nijmegen dan dat het van
            ver moet komen. Nog een mooi voorbeeld is de shiitaké van de <a href="https://versfabriek.nl/" target="_blank" rel="noreferrer noopener">Versfabriek</a>. 
            Ruud brengt mij geweldige stevige paddenstoelen. Als hij ze komt brengen 
            zijn ze vaak dezelfde ochtend nog geplukt.
          </p>

          <hr />

          <h2 className="subtitle yellow">Verpakkingsmateriaal:</h2>
          <p>
            Verder vind ik het belangrijk dat er zo min mogelijk
            verpakkingsmateriaal wordt gebruikt. Zoals je misschien al weet kan
            je je eigen bakjes meenemen, in ruil daarvoor krijg je 5% korting op
            alle gerechtjes die ik zou moeten inpakken.
          </p>
          <br />
          <p>
            Standaard gebruik ik papieren verpakkingen, of kraft (gerecycled
            materiaal). Waar ik wel plastic voor gebruik is bijvoorbeeld de
            soep. Maar dan kies ik wel weer voor harde plastic bakjes, zo kan je
            die zelf nog vaker gebruiken en deze worden wél gerecycled.
          </p>
          <br />
          <p>
            Shoyu, wasabi en ohashi (eetstokjes): Standaard geef ik er geen
            “gratis” shoyu, wasabi en ohashi bij je bestelling. Ook weer met de
            gedachte dat het alleen maar extra materiaal is wat ‘single use’ is.
            In plaats daarvan kan je een flesje shoyu, tube wasabi of echte
            ohashi bij mij kopen waar je veel langer mee doet. Duurzaam.
            Natuurlijk ontkom ik er niet aan om verpakkingsmateriaal te
            gebruiken maar alle kleine beetjes helpen.
          </p>

          <hr />

          <h2 className="subtitle yellow">Werkwijze:</h2>
          <p>
            Ik kook elke dag vers. Daarom bestel je voor dezelfde dag uiterlijk
            om 14:00 uur. Er is vaak nog wel iets aan te passen als je last minute
            bedenkt: “Er moet nog iets bij!” <strong>Bel me dan</strong> in dat geval. Wanneer je
            ook kan bellen is als je later dan 14:00 uur bedenkt dat je nog lekker
            wilt genieten van mijn kookkunsten. Vaak kan ik nog wel schuiven en
            dan staat er toch nog sushi bij jou op tafel die avond.
          </p>
          <br />
          <p>
            Ik begin vaak ‘s middags met het voorbereiden van de ingrediënten
            voor de maki rollen. De soep wordt opgezet en de tamago omelet wordt
            gerold. Zijn alle ingrediënten klaar dan moet de rijst uiterlijk om
            15 uur op het vuur staan (je leest het goed, vuur. Waarschijnlijk
            ben ik de enige Aziaat die niet met een rijstkoker kan omgaan) om
            vervolgens om 16 uur te beginnen met rollen. Vervolgens staat alles
            dus super vers voor je klaar als je het komt halen.
          </p>
          <br />
          <p>
            Ben je overtuigd? Of nog niet? Laat vooral je smaakpapillen het werk
            doen. Proef! Ik vind het fantastisch om te koken voor je. En wil je
            zelf leren hoe ik alles doe? Probeer dan eens een workshop of
            misschien wel een masterclass!
            <br /><br/>
            Tot gauw!
            <br /><br/>
            <b>Liefs Kai.</b>
          </p>
        </div>
        <div className="visie-right">
          <div className="photo-grid">
            <div className="grid-r1-c1 photo photo1" />
            <div className="grid-r2-c1 photo photo2" />
            <div className="grid-r1-c1 photo photo3" />
            <div className="grid-r2-c2 photo photo4" />
            <div className="grid-r2-c1 photo photo5" />
            <div className="grid-r1-c1 photo photo6" />
            <div className="grid-r1-c1 photo photo7" />
            <div className="grid-r1-c2 photo photo8" />
            <div className="grid-r1-c1 photo photo9" />
            <div className="grid-r2-c1 photo photo10" />
            <div className="grid-r1-c1 photo photo11" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Visie;
