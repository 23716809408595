import React from 'react';
import LogoSushiVanKai from '../../assets/img/logo.svg';

function Logo( props ) {
    return (
        <div className={`${props.className}`}>
            <img src={LogoSushiVanKai} alt="Sushi van Kai" />
        </div>
    )
}

export default Logo;