import React from "react";
import OverKaiImage from "../../assets/img/over_kai.JPG";

function OverKai() {
  return (
    <div id="over-kai" className="over-kai">
      <div className="container">
        <div className="over-kai-wrapper">
          <div className="over-kai-image">
            <img src={OverKaiImage} alt="Welkom bij Sushi van Kai" />
          </div>
          <div className="over-kai-text">
            <h1 className="section-title black">Welkom bij Sushi van Kai</h1>
            <p>
              Met een Japanse vader en Nederlandse moeder heb ik de Japanse
              keuken al vroeg ontdekt.
            </p>
            <p>
              Nu ik zelf vader ben voel ik me nóg verantwoordelijker voor een
              gezonde wereld.
            </p>
            <p>
              Alle smaken en gerechten die ik maak zijn jeugdherinneringen van
              mij, als wij vroeger thuis gasten kregen kwam de hele tafel vol te
              staan met onder andere sushi maar ook met veel bijgerechten zodat
              het de hele avond lekker genieten was. Dit wil ik graag delen met
              jullie.
            </p>
            <p>
              Daarom maak ik elke vrijdag de lekkerste{" "}
              <strong>biologische</strong> makirollen en andere Japanse
              lekkernijen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverKai;
