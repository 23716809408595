import React, { Component } from "react";
import Logo from "./layout/Logo";
import Menu from "./layout/Menu";

class NavDesktop extends Component {
    render() {

    window.onscroll = function() {scrollFunction()};
  
    function scrollFunction() {
        if (document.getElementById("nav")) {
            if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
                document.getElementById("nav").className = 'nav-desktop scrolled';
            } else {
                document.getElementById("nav").className = 'nav-desktop';
            }
        }
    }

        return (
            <div id="nav" className="nav-desktop">
                <div className="container">
                    <div className="nav-desktop-wrapper">
                        <Logo className="nav-desktop-logo" />
                        <Menu className="nav-desktop-menu" />
                    </div>
                </div>
            </div>
        )
    }
}

export default NavDesktop;