import React from "react";

function Banner() {
  return (
    <div className="banner">
      <div className="container">
        <h1 className="overlay-title">
          Elke vrijdag maak ik verse sushi en bijgerechten. Bestel vooruit of
          voor dezelfde dag tot 14 uur via de website.
        </h1>
        <div className="buttons-wrapper">
          <a href="#bestellen" className="btn btn-yellow">
            Bestellen
          </a>
        </div>
      </div>
    </div>
  );
}

export default Banner;
