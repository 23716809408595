import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

const PopupBox = ({ toggleCallPopup }) => {
  return (
    <div className="popupbox">
      <div className="popupbox__content">
        <div className="popupbox__content--header">
          <h1>Wil je vanavond nog lekker Japans eten?</h1>
          <button className="closeButton" onClick={() => toggleCallPopup()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="popupbox__content--content">
          <a href="tel:0623999074">
            <span>
              <FontAwesomeIcon icon={faPhoneAlt} />
              Bel mij op{" "}
            </span>
            06-23999074
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopupBox;
