import React, { Component } from "react";
import SushiIcon from "../../assets/img/sushi-icon.svg";

class TrainingenPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { closePopup, closeAndScroll, popupData } = this.props;
    return (
      <div className="trainingen__popup">
        <div
          className="trainingen__popup--background"
          onClick={() => closePopup()}
        />
        <div className="trainingen__popup--content">
          <div className="popup__image">
            <img
              src={require(`../../assets/img/${popupData.image}`).default}
              alt=""
            />
            <div className="image-overlay" />
            <div className="wave-overlay" />
            <div className="image-text">
              <h1 className="image-title">{popupData.title}</h1>
              <p className="image-price">
                Kosten: {popupData.price} per persoon.
              </p>
              <p className="image-duration">Duur: {popupData.duration}</p>
            </div>
          </div>
          <div className="popup__text">
            <span
              className="close-button material-icons"
              onClick={() => {
                closePopup();
              }}
            >
              close
            </span>
            <h1 className="text-title">{popupData.title}</h1>
            <div className="sumup-sushi">
              <img src={SushiIcon} alt="" className="sushi" />
              <p>
                €{popupData.price} per persoon, duur {popupData.duration}.
              </p>
            </div>
            {popupData.name === "masterclass" && (
              <div className="sumup-sushi">
                <img src={SushiIcon} alt="" className="sushi" />
                <p>€25 per persoon voor het diner.</p>
              </div>
            )}
            {popupData.name === "masterclass" && (
              <>
                <div className="sumup-sushi">
                  <img src={SushiIcon} alt="" className="sushi" />
                  <p>Aantal personen: 2</p>
                </div>
                <div className="sumup-sushi">
                  <img src={SushiIcon} alt="" className="sushi" />
                  <p>
                    Jullie gaan koken voor minimaal 4, maximaal 8 andere gasten.
                    Dat mogen vrienden, familie en/of collega's zijn. Ik vul aan
                    waar nodig.
                  </p>
                </div>
              </>
            )}
            {popupData.extraInfo && (
              <p className="extra-info">{popupData.extraInfo}</p>
            )}
            {popupData.name === "masterclass" ? (
              <p className="yellow bold popup-subtitle">
                Met de Masterclass leer ik je alles van kop tot staart.
              </p>
            ) : (
              <p className="yellow bold popup-subtitle">
                {popupData.trainingSlogan}
              </p>
            )}

            {popupData.trainingParagraphs &&
              popupData.trainingParagraphs.map((paragraph, i) => (
                <p key={i} className="paragraph">
                  {paragraph}
                </p>
              ))}
            <p className="yellow bold menu-title">Wat staat er op het menu:</p>
            {popupData.menu &&
              popupData.menu.map((item, i) => (
                <div key={i} className="sumup-sushi">
                  <img src={SushiIcon} alt="" className="sushi" />
                  <p>{item}</p>
                </div>
              ))}
            <div className="text-center">
              {popupData.name === "workshop" ? (
                <a href="http://vev.co/sushi-workshop-nl" target="_blank" rel="noreferrer noopener" className="btn btn-black">
                  Reserveer nu
                </a>
              ) : (
                <button
                className="btn btn-black"
                onClick={() => {
                  closeAndScroll();
                }}
              >
                Reserveer nu
              </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrainingenPopup;
