import React from "react";
import Logo from "./layout/Logo";
import Menu from "./layout/Menu";

function Footer() {
  const date = new Date();
  const thisYear = date.getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <Logo className="footer-logo" />
        <Menu className="footer-menu" />
        <div className="copyright">
          <ul>
            <li>&copy; Sushi van Kai - {thisYear}</li>
            <li>
              Website gemaakt door {/* eslint-disable-next-line */}
              <a href="https://studiomaandag.nl" target="_blank">
                Studio Maandag
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
