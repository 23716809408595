import React from "react";

function Menu(props) {
  const handleClick = (props) => {
    if (props.toggleNavigation) {
      props.toggleNavigation();
    }
  };

  return (
    <div className={props.className}>
      <ul>
        <li>
          <a
            href="#home"
            onClick={() => {
              handleClick(props);
            }}
          >
            Home
          </a>
        </li>
        <li>
          <a
            href="#over-kai"
            onClick={() => {
              handleClick(props);
            }}
          >
            Over Kai
          </a>
        </li>
        <li>
          <a
            href="#visie"
            onClick={() => {
              handleClick(props);
            }}
          >
            Visie
          </a>
        </li>
        <li>
          <a
            href="#bestellen"
            onClick={() => {
              handleClick(props);
            }}
          >
            Bestellen
          </a>
        </li>
        <li>
          <a
            href="#trainingen"
            onClick={() => {
              handleClick(props);
            }}
          >
            Trainingen
          </a>
        </li>
        <li>
          <a
            href="#contact"
            onClick={() => {
              handleClick(props);
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
