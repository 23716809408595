import React from "react";
import BestellenImage from "../../assets/img/bestellen.JPG";

function Bestellen(props) {
  // const handleClick = () => {
  //   props.openOrderForm();
  // };
  return (
    <React.StrictMode>
      <div id="bestellen" className="bestellen">
        <div className="container">
          <h1 className="section-title yellow">Bestellen</h1>
          <div className="bestellen-wrapper">
            <div className="bestellen-image">
              <img src={BestellenImage} alt="" />
            </div>
            <div className="bestellen-text">
              <p>
                Wil je het <strong>ophalen</strong>? Dat kan tussen 16:30 en
                17:00. Vanaf 17:30 spring ik op mijn bakfiets en probeer ik de
                mooiste route te rijden om dan om 18:30 de laatste te bezorgen.
              </p>
              <p>
                Kom je het zelf ophalen? Neem je eigen bakjes mee. Dat bespaart
                weer wegwerpverpakkingen. Als dank krijg je van mij 5% korting
                op je bestelling. <strong>Laat het van te voren wel weten
                met een appje of dm dan hou ik het apart voor je!</strong>
              </p>
              <p>
                Bezorgen in Nijmegen-Noord (Lent en Oosterhout) is gratis. Woon
                je daarbuiten? Vraag dan naar de mogelijkheden.
              </p>
              <p>
                Betalen kan makkelijk met <strong>pin</strong> of een{" "}
                <strong>tikkie</strong>.
              </p>
            </div>
          </div>
          <div className="button-wrapper text-center">
            {/* <button onClick={handleClick} className="btn btn-yellow">
              Bestellen
            </button> */}
            <a href="http://oopen.co/sushi-van-kai-bezorging" target="_blank" rel="noreferrer nooeper" className="btn btn-yellow">Bezorgen</a>
            <a href="http://oopen.co/sushi-van-kai" target="_blank" rel="noreferrer nooeper" className="btn btn-yellow">Afhalen</a>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Bestellen;
