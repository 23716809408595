import React, { Component } from "react";
import { isBrowser } from "react-device-detect";
import Footer from "./Components/Footer";
import Main from "./Components/Main";
import Bestelformulier from "./Components/main/Bestelformulier";
import NavDesktop from "./Components/NavDesktop";
import NavMobile from "./Components/NavMobile";

class Container extends Component {
  constructor() {
    super();
    this.state = {
      formIsOpen: false,
      callPopupVisible: true,
    };
  }

  componentDidMount() {
    const date = new Date();
    const dayOfTheWeek = date.getDay();
    const time = date.getHours();

    if (dayOfTheWeek === 5) {
      if (time >= 14 && time < 18) {
        this.setState({
          callPopupVisible: true,
        });
      } else {
        this.setState({
          callPopupVisible: false,
        });
      }
    } else {
      this.setState({
        callPopupVisible: false,
      });
    }
  }

  render() {
    const { formIsOpen, callPopupVisible } = this.state;

    const openOrderForm = () => {
      this.setState({ formIsOpen: true });

      const main = document.getElementById("main");
      if (!isBrowser) {
        main.classList = "d-none";
      } else {
        main.classList = "overflow-hidden";
      }
    };

    const closeOrderFrom = () => {
      this.setState({ formIsOpen: false });

      const main = document.getElementById("main");
      main.classList = "";
    };

    const toggleCallPopup = () => {
      this.setState({
        callPopupVisible: !callPopupVisible,
      });
    };

    return (
      <React.StrictMode>
        <nav>
          <NavDesktop />
          <NavMobile />
        </nav>
        <div id="main">
          <Main
            openOrderForm={openOrderForm}
            toggleCallPopup={toggleCallPopup}
            callPopupVisible={callPopupVisible}
          />
          <Footer />
        </div>
        {formIsOpen && (
          <Bestelformulier
            active={formIsOpen}
            closeOrderForm={closeOrderFrom}
          />
        )}
      </React.StrictMode>
    );
  }
}

export default Container;
