import './assets/scss/main.scss';
import Container from './Container';

function App() {
  return (
      <Container />
  );
}

export default App;
