import React, { Component } from "react";
import Logo from './layout/Logo';
import Menu from './layout/Menu';

class NavMobile extends Component {
    constructor() {
        super();
        this.state = {
            navigationIsOpen : false,
        }
    }

    render() {
         const navigationIcon = this.state.navigationIsOpen ? 'close' : 'menu';

        const toggleNavigation = () => {
            this.setState({
                navigationIsOpen : !this.state.navigationIsOpen,
            })

            this.state.navigationIsOpen ? document.getElementById('main').className = '' : document.getElementById('main').className = 'overflow-hidden';
        }
        return(
            <div className="nav-mobile">
                <div className="navigation">
                    <span className="material-icons" onClick={() => {toggleNavigation()}}>{navigationIcon}</span>
                </div>
                <div className={`nav-mobile-wrapper ${this.state.navigationIsOpen ? 'open' : 'closed'}`}>
                    <Logo className="nav-mobile-logo"/>
                    <Menu className="nav-mobile-menu" toggleNavigation={toggleNavigation}/>
                    <div className="copyright text-center">
                        <p>&copy; Sushi van Kai 2021</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavMobile;