import React, { Component } from "react";
// import BestelformulierImage from '../../assets/img/bestelformulier.JPG';

class Bestelformulier extends Component {
    constructor(props) {
        super();
        // this.submitForm = this.submitForm.bind(this);
        // this.state = {
        //     status: ""
        // };
    }
    render() {

        const handleClick = () => {
            this.props.closeOrderForm();
        }

        return (
            <div id="bestelformulier" className={`bestelformulier ${this.props.active ? 'active' : ''}`}>
                <div className="container">
                <div className="bestelformulier-wrapper">
                    <button className="back-button btn btn-yellow" onClick={handleClick}>Terug</button>
                    <iframe title="bestelformulier" src="https://oopen.co/sushi-van-kai-bezorging" className="bestel-iframe"/>
                </div>
                
                </div>
                </div>
        )
    }
}

export default Bestelformulier;