import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../layout/Logo";

function Header() {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <header id="home" className="header">
      <Slider {...settings}>
        <div className="header__image header__image--1" />
        <div className="header__image header__image--2" />
        <div className="header__image header__image--3" />
        {/* <div className="header__image header__image--4" /> */}
      </Slider>
      <Logo className="header-logo" />
      <h1 className="overlay-title">Japans, vega(n) en biologisch.</h1>
      <div className="btn-wrapper">
        <a href="#bestellen" className="btn btn-yellow">
          Bestellen
        </a>
        <a href="#visie" className="btn btn-black">
          Visie en Kookwijze
        </a>
      </div>
      <h3 className="overlay-title">
        Dezelfde dag vóór 14 uur bestellen of bestel van tevoren.
      </h3>
      <div className="wave-overlay" />
    </header>
  );
}

export default Header;
